import React, { Component } from 'react';
import './App.css';
import streamSaver from 'streamsaver'
import _ from 'lodash'
import $ from 'jquery'
import Hasher from './Hasher';
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import ColumnSelect from './components/column_select.js.jsx'

const ALGORITHMS = ['SHA1', 'SHA256', 'MD5']

class App extends Component {
  constructor(props) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleAlgorithmSelection = this.handleAlgorithmSelection.bind(this);
    this.handleColumnSelection = this.handleColumnSelection.bind(this);

    this.state = this.getOriginalState();
  }

  render() {
    if (!window.WritableStream) {
      return this.renderUnsupported();
    } else {
      return (
        <div className='container-fluid'>
          <div className='row-fluid no-gutters'>
            <div className='col-lg-8 col-lg-offset-2 file-section'>
              {this.state.file ? this.renderSelectedFilePanel() : <Dropzone className='file-drop' activeClassName='file-drop-active' onDrop={this.onDrop}>Pick a file</Dropzone>}
            </div>
          </div>
          <div className='row-fluid'><div className='col-lg-8 col-lg-offset-2'>{this.renderAlgorithmSelection()}</div></div>
          <div className='row-fluid'><div className='col-lg-8 col-lg-offset-2'>{this.renderDownloadButton()}</div></div>
        </div>
      );
    }
  }

  renderUnsupported() {
    return (
      <div className='container-fluid'>
        <div className='row-fluid no-gutters'>
          <div className='col-lg-8 col-lg-offset-2 unsupported-text'>
            Unfortunately, we  have limited browser support and rely on features currently only available in Google Chrome. Please switch browsers to continue.
          </div>
        </div>
      </div>
    );
  }

  renderSelectedFilePanel() {
    return (
      <div className='selected-file'>
        {this.renderFilePreview()}
        {this.state.columns.length > 1 ? <ColumnSelect selectedColumn={this.state.selectedColumn} columns={this.state.columns} handleColumnSelection={this.handleColumnSelection} /> : null}
      </div>
    )
  }

  renderFilePreview() {
    return (
      <div className='file-preview'>
        <img className='file-icon' src='file-icon.png' alt=''></img>
        <div className='file-name'>{this.state.file.name}</div>
        <div className='file-size'>{this.humanFileSize(this.state.file.size)}</div>
        <div className='remove-file'>[<a onClick={this.handleRemoveFile}>REMOVE FILE</a>]</div>
      </div>
    )
  }

  renderAlgorithmSelection() {
    return (
      <div className="algorithm-selection">
        {_.map(ALGORITHMS, (a) => <span className={`algorithm-button default-button${this.state.algorithm === a ? ' active' : ''}`} key={a} onClick={this.handleAlgorithmSelection}>{a}</span>)}
      </div>
    )
  }

  renderDownloadButton() {
    return (
      <div onClick={this.handleDownload} className={`default-button download-button${this.readyToDownload() ? '' : ' disabled'}`}>Download</div>
    )
  }

  onDrop(acceptedFiles) {
    this.setState({ error: null, file: null, delimiter: null, selectedColumn: 0, columns: null });
    this.processFile(acceptedFiles[0]);
  }

  handleRemoveFile() {
    this.setState({ file: null });
  }

  handleColumnSelection(e) {
    this.setState({ selectedColumn: Number($(e.target).val()) });
  }

  handleAlgorithmSelection(e) {
    this.setState({ algorithm: $(e.target).text() });
  }

  handleDownload() {
    const writer = streamSaver.createWriteStream('test-file-download.txt').getWriter();
    const encoder = new TextEncoder();
    const hasher =  new Hasher(this.state.algorithm, this.state.selectedColumn);

    Papa.parse(this.state.file, {
      step: (results, parser) => {
        let delimiter = results.meta.delimiter;
        let hashedLineArray = hasher.hashLine(results.data[0]);
        let outputLine = hashedLineArray.join(delimiter);
        writer.write(encoder.encode(outputLine + results.meta.linebreak));
      },
      complete: function() {
        writer.close();
      },
      fastMode: false
    });
  }

  processFile(file) {
    Papa.parse(file, {
      step: (results, parser) => {
        parser.abort();
        const header = results.data[0];

        this.setState({
          file,
          delimiter: results.meta.delimiter,
          columns: header
        });
      },
      fastMode: false
    })
  }

  readyToDownload() {
    const state = this.state;
    return state.file && state.algorithm;
  }

  humanFileSize(size) {
    var i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  getOriginalState() {
    return { error: null, file: null, algorithm: 'SHA256', delimiter: null, selectedColumn: 0, columns: null }
  }

  resetState() {
    this.setState(this.getOriginalState());
  }
}

export default App;

import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import 'selectric/public/selectric.css'
require('selectric');

export default class ColumnSelect extends React.Component {
  render() {
    return (
      <div className='column-select'>
        <label>
          Pick a Column to Hash
        </label>
        <select ref={(e) => this.select = e} value={this.props.selectedColumn} onChange={this.props.handleColumnSelection}>
          {_.map(this.props.columns, (c, i) => { return <option value={i} key={i}>{`${i + 1}: ${c}`}</option> })}
        </select>
      </div>
    )
  }

  componentDidMount() {
    $(this.select).selectric();
  }
}

import Hashes from 'jshashes'

class Hasher {
  constructor(algorithm, columnIndex) {
    this.hasher = new Hashes[algorithm]();
    this.columnIndex = columnIndex;
  }

  hashLine(rowArray) {
    const newArray = rowArray.slice(0);
    newArray[this.columnIndex] = this.hasher.hex(newArray[this.columnIndex]);

    return newArray;
  }
}

export default Hasher;
